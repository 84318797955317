import React from 'react';

import Icon from './Icon';

export default function MergeIcon({ className = '' }: { className?: string }): JSX.Element {
  return (
    <Icon text="Merge">
      <svg
        className={className}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="black"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.75813 3.3089C2.15021 3.17531 2.57635 3.38485 2.70994 3.77693C3.14011 5.03941 4.06116 5.87643 5.22129 6.41732C6.39371 6.96395 7.77709 7.18895 9.0177 7.21821C10.1105 7.24398 11.2627 7.26331 12.1847 7.27664L10.466 5.53366C10.1752 5.23872 10.1785 4.76386 10.4734 4.47303C10.7684 4.18219 11.2432 4.18552 11.5341 4.48046L15.0607 8.05686L11.5266 11.5411C11.2316 11.8319 10.7567 11.8286 10.4659 11.5336C10.1751 11.2386 10.1785 10.7638 10.4735 10.473L12.1935 8.77716C11.2255 8.76694 10.0242 8.75706 9.00002 8.75706C6.46432 8.75706 3.58818 9.69864 2.70838 12.2277C2.57229 12.6189 2.14482 12.8257 1.7536 12.6896C1.36238 12.5535 1.15556 12.1261 1.29166 11.7348C1.96332 9.80409 3.42997 8.63747 5.06446 7.98137C4.90356 7.91808 4.74436 7.84998 4.58745 7.77682C3.15657 7.1097 1.88373 6.00293 1.2901 4.26071C1.15651 3.86863 1.36605 3.44249 1.75813 3.3089Z"
        />
      </svg>
    </Icon>
  );
}
